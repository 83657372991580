<template>
  <div class="editBookingPage">
    <back-title confirm-click @click="goBack">
      {{ $t("Edit booking") }}
      <span
        v-if="booking && booking.registrationCode"
        class="detailBookingPage__reg-code"
      >
        {{ booking.registrationCode }}
      </span>
    </back-title>
    <Loader v-if="isLoading" color="primary" size="m" class="refund-loader" />
    <div v-else-if="booking" class="editBookingPage__content">
      <BookingForm
        :value="datedBooking"
        :isSubmitting="isSubmitting"
        :isShowOverbookOption="isShowOverbookOption"
        @submit="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import { mapActions, mapState } from "vuex";
import moment from "moment";
import { alertRefundResult, getBookingPrice } from "@/helpers/utils";
import BookingForm from "@/components/bookings/BookingForm";
import dialog from "@/plugins/dialog";

export default {
  name: "EditBookingPage",
  components: {
    BookingForm,
    BackTitle,
  },
  data: () => ({
    isLoading: true,
    isSubmitting: false,
    isShowOverbookOption: false,
  }),
  computed: {
    ...mapState({
      venue: (state) => state.venues.selectedVenue,
      booking: (state) => state.bookings.editedItem,
    }),
    datedBooking() {
      if (!this.booking) return;
      return {
        ...this.booking,
        date: moment.utc(this.booking.dateIso).format("YYYY-MM-DD"),
      };
    },
    price() {
      if (!this.booking?.checkoutInfo) return {};

      return getBookingPrice(this.booking.checkoutInfo);
    },
  },
  async created() {
    const status = this.$route.query.bookingPaymentStatus;
    if (!status) {
      try {
        await this.getBooking(this.$route.params.id);
      } finally {
        this.isLoading = false;
      }
    } else {
      await this.$router.replace({ query: null });
      const isSuccessPaid = status === "success";
      setTimeout(async () => {
        await this.goBack();
        await this.showPaymentResult(isSuccessPaid);
      }, 0);
    }
  },
  methods: {
    ...mapActions({
      getBooking: "bookings/fetchBookingById",
      editBooking: "bookings/editBooking",
    }),
    async showPaymentResult(isSuccessPaid) {
      const alertOptions = isSuccessPaid
        ? {
            title: "Payment was successful!",
            okText: "Done",
          }
        : {
            title: "Some problem with payment...",
            okText: "Done",
          };
      await dialog.alert(alertOptions);
    },
    goBack() {
      this.$router.push({
        name: "BookingDetail",
      });
    },
    async handleSubmit(data, refundAmount, isSkipPayment) {
      try {
        this.isSubmitting = true;
        const {
          date,
          slots,
          isDeposit,
          reservationInfo,
          playersCount,
          productId,
          customPrice,
          isWalkIn,
          withoutPaymentOption,
          selectedGroupId,
          selectedGroup,
          affiliateId,
          isAllowOverbook,
        } = data;
        const { isAutoPaymentSucceeded, refundResult } = await this.editBooking(
          {
            productId,
            date,
            slots,
            reservationInfo,
            playersCount,
            checkoutSession: {
              isSkipPayment,
              isDeposit,
              customPrice,
              withoutPaymentOption,
            },
            isWalkIn,
            selectedGroupId,
            selectedGroup,
            affiliateId,
            isAllowOverbook,
          }
        );

        if (isAutoPaymentSucceeded) {
          await this.showPaymentResult(true);
        } else if (refundResult) {
          await alertRefundResult(refundResult);
        } else if (!isSkipPayment) {
          await this.$router.push({ name: "BookingCheckout" });
          return;
        }
        await this.goBack();
      } catch (err) {
        if (err?.response?.data?.code === "not-enough-resources") {
          this.isShowOverbookOption = true;
        }
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
<style lang="scss">
.editBookingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  height: 100%;
  width: 100%;

  &__content {
    width: 100%;
  }
}
</style>
